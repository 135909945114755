<template>
  <div>
    <quill-editor
      ref="myQuillEditor"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)"
      class="editor myeditor"
    />
  </div>
</template>

<script>
// 引入富文本
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "Editor",
  props: ["htmlData"],
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
              [{ header: 1 }, { header: 2 }], // 几级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 颜色选择
              ["link"],
              // [{ 'align': [] }], // 居中
              // [{ 'direction': 'rtl' }],// 文字输入方向
              ["clean"], // 清除样式
              ["link"],
              // ["link", "image", "video"]
            ],
          },
        },
      },
      content: "",
    };
  },
  computed: {},
  created() {
    this.content = this.htmlData;
  },
  methods: {
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      // this.content = html
      this.$emit("getEditorHtml", { html: html });
    },
  },
};
</script>

<style lang="scss" scoped></style>
